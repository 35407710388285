export const integralColumns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '名称',
      align: 'center',
      dataIndex: 'name'
    },
    {
      title: '分数',
      align: 'center',
      width: '100px',
      dataIndex: 'num'
    },
    {
      title: '操作类型',
      width: '120px',
      align: 'center',
      dataIndex: 'systemModule'
    }
  ]
}